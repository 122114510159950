import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Open Source Projects`}</h2>
    <ProjectCard title="Trino" link="https://github.com/eneserdogan/trino" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Trino: Master your translations with command line!
    </ProjectCard>
    <ProjectCard title="Cizi" link="https://github.com/eneserdogan/cizi" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Clean And Minimalistic Personel Blog Theme
    </ProjectCard>
    <ProjectCard title="iftar" link="https://github.com/eneserdogan/iftar" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Iftar CLI allows a quick and easy prayer times in the command line
    </ProjectCard>
    <ProjectCard title="Head" link="https://github.com/eneserdogan/head" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  The (un)official Jade Version of HEAD : A list of everything that could go in the `head` of your document
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      